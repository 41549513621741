import { useSettings } from "../hooks/useSettings";

export const EnergyProgress = ({
  energyCount,
  maxEnergy,
}: {
  energyCount: number;
  maxEnergy: number | undefined;
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="mt-2"
    >
      <div className="w-3/4 bg-[#FD5463] flex p-2 rounded items-center content-center justify-center">
        <div
          style={{
            fontSize: "23px",
          }}
          className="ml-4"
        >
          {energyCount}/{maxEnergy || 1000}
        </div>
      </div>
    </div>
  );
};
