import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { useWebSocket } from "../../hooks/useWebsocket";
import { useUser } from "../../hooks/useUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BoostButton } from "../../components/BoostButton";
import { RestoreSvg } from "./RestoreSvg";
import { MassTapSvg } from "./MassTapSvg";
import { BoostModal } from "./BoostModal";
import { useSettings } from "../../hooks/useSettings";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";
import { useNavigate } from "react-router-dom";

type Boost = {
  id: number;
  Icon: any;
  title: string;
  description: string;
  additionalInfo?: (level: number) => string;
  eggIcon: boolean;
  purchaseText: (nextCost: number) => string;
};

const boosts: Boost[] = [
  {
    id: 0,
    Icon: RestoreSvg,
    title: "Restore taps",
    additionalInfo: (level: number) => ``,
    description: "Restore your taps and continue mining!",
    eggIcon: false,
    purchaseText: (nextCost: number) => "Get it for free",
  },
  {
    id: 1,
    Icon: MassTapSvg,
    title: "Mass tap",
    description: "Increases the amount of currency per click",
    additionalInfo: (level: number) => `Adds +1 tap for ${level} lvl`,
    eggIcon: true,
    purchaseText: (nextCost: number) => `Upgrade for ${nextCost} coins`,
  },
  {
    id: 2,
    Icon: RestoreSvg,
    title: "Upgrade energy",
    description: "Increases the amount of energy available to the user",
    additionalInfo: (level: number) => `Adds 500 energy for ${level} lvl`,
    eggIcon: true,
    purchaseText: (nextCost: number) => `Upgrade for ${nextCost} coins`,
  },
];
export const Boosts = () => {
  const { webSocket } = useWebSocket();
  const { user } = useUser();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedBoost, setSelectedBoost] = useState<Boost | null>(null);
  const { startClickUpgradeCost, startEnergyUpgradeCost } = useSettings();
  const notifyContext = useContext(NotifyContext);
  const { maxClickLevel, maxEnergyLevel } = useSettings();
  const navigate = useNavigate();

  const [isEnergyUpgrading, setEnergyUpgrading] = useState(false);
  const [isEnergyRestoring, setEnergyRestroring] = useState(false);
  const [isClickUpgrading, setClickUpgrading] = useState(false);

  useEffect(() => {
    if (webSocket) {
      const handleBoostActivated = ({ success, message }: any) => {
        const notify: NotifyMessage = {
          status: "ok",
          message: message,
        };
        setEnergyRestroring(false);
        notifyContext?.setNotify(notify);
      };

      webSocket.on("boostActivated", handleBoostActivated);

      webSocket.on("clickPowerUpgraded", ({ success }) => {
        setClickUpgrading(false);
        console.log("clickPowerUpgraded", success);

        const notify: NotifyMessage = {
          status: success ? "ok" : "error",
          message: success
            ? "The power of your click has been enhanced"
            : "The power of your click was not increased",
        };
        notifyContext?.setNotify(notify);
      });

      webSocket.on("energyUpgraded", ({ success }) => {
        setEnergyUpgrading(false);
        const notify: NotifyMessage = {
          status: success ? "ok" : "error",
          message: success
            ? "Your energy level has been enhanced"
            : "Your energy level was not enhanced",
        };
        notifyContext?.setNotify(notify);
      });
      return () => {
        webSocket.off("boostActivated", handleBoostActivated);
        webSocket.off("clickPowerUpgraded");
      };
    }
  }, [webSocket]);

  useEffect(() => {
    if (typeof window.Telegram.WebApp !== "undefined") {
      console.log("show");

      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(function () {
        window.history.back();
      });
    }
    return () => {
      if (typeof window.Telegram.WebApp !== "undefined") {
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, []);

  const activateFullEnergyBoost = () => {
    if (webSocket) {
      if (fullEnergyActivates < 3) {
        setEnergyRestroring(true);
        webSocket.emit(
          "activateBoost",
          JSON.stringify([user?.tgId, "fullEnergyBoost"])
        );
        // const notify: NotifyMessage = {
        //   status: "ok",
        //   className: "h-72",
        //   message: "The energy has been restored",
        // };
        // notifyContext?.setNotify(notify);
      } else {
        // const notify: NotifyMessage = {
        //   status: "error",
        //   message: "You can't restore energy today",
        // };
        // notifyContext?.setNotify(notify);
      }
    }
  };

  const improveClick = () => {
    if (!!user && user?.clickPower >= maxClickLevel) {
      const notify: NotifyMessage = {
        status: "unknown",
        message: "You have reached the maximum clicker level",
      };
      notifyContext?.setNotify(notify);
      return;
    }
    if (
      webSocket &&
      startClickUpgradeCost * 2 ** ((user?.clickPower || 2) - 1) <=
        (user?.balance || 0)
    ) {
      setClickUpgrading(true);
      webSocket.emit("upgradeClick", user?.tgId);
    } else {
      const notify: NotifyMessage = {
        status: "error",
        message: "You don't have enough balance",
      };
      notifyContext?.setNotify(notify);
    }
  };

  const upgradeEnergy = () => {
    if (!!user?.energyLevel && user.energyLevel >= maxEnergyLevel) {
      const notify: NotifyMessage = {
        status: "unknown",
        message: "You have reached the maximum energy level",
      };
      notifyContext?.setNotify(notify);
      return;
    }

    if (
      webSocket &&
      startEnergyUpgradeCost * 2 ** ((user?.energyLevel || 2) - 1) <=
        (user?.balance || 0)
    ) {
      webSocket.emit("upgradeEnergy", user?.tgId);
      setEnergyUpgrading(true);
    } else {
      const notify: NotifyMessage = {
        status: "error",
        message: "You don't have enough balance",
      };
      notifyContext?.setNotify(notify);
    }
  };

  const energyDisabled = useMemo(() => {
    if (user) {
      return (
        Date.now() - user?.lastFullEnergyTimestamp < 1000 * 60 * 60 * 24 &&
        user?.fullEnergyActivates >= 3
      );
    } else {
      return true;
    }
  }, [user]);

  const fullEnergyActivates = useMemo(() => {
    if (user) {
      if (Date.now() - user?.lastFullEnergyTimestamp > 1000 * 60 * 60 * 24) {
        return 0;
      }
    }
    return user?.fullEnergyActivates || 0;
  }, [user]);

  const onClose = () => {
    setModalOpen(false);
  };
  const onPurchase = () => {
    // onClose();

    switch (selectedBoost?.id) {
      case 0:
        activateFullEnergyBoost();
        break;
      case 1:
        improveClick();
        break;
      case 2:
        upgradeEnergy();
        break;
    }
  };

  const purchaseText = useMemo(() => {
    switch (selectedBoost?.id) {
      case 0:
        return selectedBoost.purchaseText(1);
      case 1:
        return selectedBoost.purchaseText(
          startClickUpgradeCost * 2 ** ((user?.clickPower || 2) - 1)
        );
      case 2:
        return selectedBoost.purchaseText(
          startEnergyUpgradeCost * 2 ** ((user?.energyLevel || 2) - 1)
        );
      default:
        return "";
    }
  }, [selectedBoost, user]);

  const additionalInfo = useMemo(() => {
    if (!selectedBoost?.additionalInfo) {
      return "";
    }
    switch (selectedBoost?.id) {
      case 0:
        return "";
      case 1:
        return selectedBoost.additionalInfo((user?.clickPower || 1) + 1);
      case 2:
        return selectedBoost.additionalInfo((user?.energyLevel || 1) + 1);

      default:
        return "";
    }
  }, [selectedBoost, user]);

  return (
    <div className="p-5 pt-0 rounded-lg max-w-md mx-auto">
      <div className="mt-4">
        <div className="flex justify-center w-full mb-8">
          <div className="w-min">
            <BoostButton />
          </div>
        </div>
        <div className="flex justify-around">
          <div
            style={{
              width: "-webkit-fill-available",
            }}
            className={
              "flex flex-col justify-center bg-[#383838] p-4 rounded-xl mx-2 " +
              (isEnergyRestoring ? "opacity-50" : "opacity-100")
            }
          >
            {isEnergyRestoring && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 text-white text-center rounded-2xl">
                <div className="loader"></div>
              </div>
            )}
            <div className="flex justify-center h-0">
              <div
                className="w-16 h-16 relative bg-[#FD5C63] rounded-full flex justify-center items-center"
                style={{
                  top: "-50px",
                  boxShadow: "0px 0px 25.56px 0px #438EFE",
                }}
              >
                <RestoreSvg />
              </div>
            </div>
            <div className="flex justify-center mb-2 mt-5">Restore taps</div>
            <div className="flex justify-center text-xl mb-1">
              {3 - fullEnergyActivates}/3
            </div>
            <button
              disabled={energyDisabled}
              onClick={() => {
                setModalOpen(true);
                setSelectedBoost(boosts[0]);
              }}
              className="p-1 rounded-lg"
              style={{
                background: "linear-gradient(180deg, #F4895D 0%, #FF4C64 100%)",
              }}
            >
              Restore
            </button>
          </div>
          <div
            style={{
              width: "-webkit-fill-available",
            }}
            className={
              "relative flex flex-col justify-center bg-[#383838] p-4 rounded-xl mx-2 " +
              (isClickUpgrading ? "opacity-50" : "opacity-100")
            }
          >
            {isClickUpgrading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 text-white text-center rounded-2xl">
                <div className="loader"></div>
              </div>
            )}
            <div className="flex justify-center h-0">
              <div
                className="w-16 h-16 relative bg-[#FD5C63] rounded-full flex justify-center items-center"
                style={{
                  top: "-50px",
                  boxShadow: "0px 0px 25.56px 0px #438EFE",
                }}
              >
                <MassTapSvg />
              </div>
            </div>
            <div className="flex justify-center mb-2 mt-5">Mass tap</div>
            <div className="flex justify-center text-xl mb-1">
              {user?.clickPower} lvl
            </div>
            <button
              onClick={() => {
                setModalOpen(true);
                setSelectedBoost(boosts[1]);
              }}
              className="p-1 rounded-lg"
              style={{
                background:
                  !!user && user?.clickPower >= maxClickLevel
                    ? "linear-gradient(180deg, #C2C2C2 0%, #A1A1A1 100%)"
                    : "linear-gradient(180deg, #F4895D 0%, #FF4C64 100%)",
              }}
            >
              {!!user && user?.clickPower >= maxClickLevel
                ? "Max level"
                : "Improve"}
            </button>
          </div>
        </div>

        <div className="flex justify-around mt-12">
          <div
            style={{
              width: "-webkit-fill-available",
            }}
            className={
              "relative flex flex-col justify-center bg-[#383838] p-4 rounded-xl mx-2 " +
              (isEnergyUpgrading ? "opacity-50" : "opacity-100")
            }
          >
            {isEnergyUpgrading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 text-white text-center rounded-2xl">
                <div className="loader"></div>
              </div>
            )}
            <div className="flex justify-center h-0">
              <div
                className="w-16 h-16 relative bg-[#FD5C63] rounded-full flex justify-center items-center"
                style={{
                  top: "-50px",
                  boxShadow: "0px 0px 25.56px 0px #438EFE",
                }}
              >
                <RestoreSvg />
              </div>
            </div>
            <div className="flex justify-center mb-2 mt-5">Upgrade energy</div>
            <div className="flex justify-center text-xl mb-1">
              {user?.energyLevel} lvl
            </div>
            <button
              disabled={!!user && user?.energyLevel >= maxEnergyLevel}
              onClick={() => {
                setModalOpen(true);
                setSelectedBoost(boosts[2]);
              }}
              className="p-1 rounded-lg"
              style={{
                background:
                  !!user && user?.energyLevel >= maxEnergyLevel
                    ? "linear-gradient(180deg, #C2C2C2 0%, #A1A1A1 100%)"
                    : "linear-gradient(180deg, #F4895D 0%, #FF4C64 100%)",
              }}
            >
              {!!user && user?.energyLevel >= maxEnergyLevel
                ? "Max level"
                : "Upgrade"}
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && selectedBoost && (
        <BoostModal
          Icon={selectedBoost.Icon}
          eggIcon={selectedBoost.eggIcon}
          title={selectedBoost.title}
          purchaseText={purchaseText}
          additionalInfo={additionalInfo}
          onClose={onClose}
          onPurchase={onPurchase}
          description={selectedBoost.description}
        />
      )}
    </div>
  );
};
