import { useNavigate } from "react-router-dom";

export const BoostButton = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: "#101010",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      }}
      className="p-3 rounded-xl"
      onClick={() => {
        navigate("/boosts");
      }}
    >
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.8719 0.903181C22.8628 0.859188 22.8414 0.818695 22.8101 0.78645C22.7788 0.754205 22.739 0.731544 22.6953 0.721124C19.7819 0.0088113 13.0503 2.54717 9.40313 6.1928C8.75264 6.83803 8.15962 7.53874 7.63081 8.28696C6.50613 8.18747 5.38145 8.27054 4.42291 8.68838C1.71842 9.87872 0.930992 12.9846 0.711628 14.3207C0.699185 14.3938 0.703879 14.4689 0.725339 14.5399C0.746799 14.6109 0.784441 14.6759 0.835304 14.7299C0.886167 14.7839 0.948865 14.8254 1.01846 14.8511C1.08806 14.8767 1.16267 14.8859 1.23641 14.8778L5.57943 14.3988C5.58253 14.7262 5.60229 15.0533 5.63862 15.3787C5.66048 15.6047 5.76079 15.8159 5.92215 15.9757L7.60395 17.6535C7.76382 17.8146 7.97494 17.9149 8.20086 17.937C8.52446 17.9732 8.8497 17.993 9.17531 17.9962L8.69878 22.3337C8.69083 22.4075 8.70007 22.482 8.72577 22.5516C8.75146 22.6211 8.79292 22.6838 8.8469 22.7346C8.90087 22.7854 8.96588 22.8231 9.03684 22.8446C9.10781 22.8661 9.18278 22.8709 9.25589 22.8585C10.5895 22.6446 13.7009 21.8572 14.8843 19.1527C15.3021 18.1942 15.3877 17.075 15.2912 15.9558C16.0412 15.4269 16.7437 14.8337 17.3908 14.1829C21.0493 10.5428 23.5733 3.96135 22.8719 0.903181ZM13.7407 9.84041C13.4066 9.50656 13.179 9.08112 13.0868 8.61792C12.9945 8.15473 13.0417 7.67457 13.2224 7.2382C13.4031 6.80182 13.7091 6.42883 14.1018 6.1664C14.4945 5.90398 14.9561 5.76391 15.4284 5.76391C15.9007 5.76391 16.3624 5.90398 16.7551 6.1664C17.1478 6.42883 17.4538 6.80182 17.6345 7.2382C17.8151 7.67457 17.8623 8.15473 17.7701 8.61792C17.6778 9.08112 17.4503 9.50656 17.1162 9.84041C16.8947 10.0623 16.6316 10.2383 16.342 10.3583C16.0524 10.4784 15.7419 10.5402 15.4284 10.5402C15.1149 10.5402 14.8045 10.4784 14.5149 10.3583C14.2253 10.2383 13.9622 10.0623 13.7407 9.84041Z"
          fill="#FD5C63"
        />
        <path
          d="M7.48948 18.8685C7.2169 19.1416 6.77966 19.2481 6.25338 19.3391C5.071 19.5406 4.02691 18.5189 4.23931 17.3235C4.32039 16.8704 4.56015 16.2352 4.70938 16.086C4.74199 16.054 4.7637 16.0125 4.77142 15.9675C4.77914 15.9225 4.77248 15.8761 4.75238 15.8351C4.73228 15.7941 4.69977 15.7604 4.65945 15.7389C4.61914 15.7174 4.57308 15.7092 4.52782 15.7154C3.86662 15.7963 3.25149 16.096 2.78036 16.567C1.61092 17.7374 1.50049 22.0804 1.50049 22.0804C1.50049 22.0804 5.84599 21.97 7.01544 20.7996C7.48782 20.3288 7.78797 19.7127 7.86753 19.0506C7.88593 18.8427 7.63274 18.7188 7.48948 18.8685Z"
          fill="#FD5C63"
        />
      </svg>
      <div className="ml-3">Boost</div>
    </div>
  );
};
